<template>
  <div class="base-interaction lights-interaction">
    <div class="base-interaction__container">
      <video muted playsinline ref="video">
        <source src="assets/videos/day_cycle_2.mp4" type="video/mp4"/>
        Your browser does not support the video tag.
      </video>

      <div class="dragger__container" ref="dragger_container">
        <div ref="dragger" class="lights-interaction__dragger">
          <inline-svg :src="require('@/assets/svg/lights_dragger.svg')" @click="startAutoPlay" />          
        </div>
      </div>

      <Button class="button--small" @click.native="onExitClick">EXIT</Button>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import Draggable from "gsap/Draggable";
import DownloadHelperMixin from "../../../mixins/DownloadHelperMixin";
import Button from "../../../components/form/Button";

export default {
  name: "LightsInteraction",
  mixins: [DownloadHelperMixin],
  components: { Button },
  data() {
    return {
      percent: 0,
      draggable: undefined,
      autoplay: false,
      interval:undefined
    };
  },
  methods: {
    stopAutoPlay(){
      this.autoplay = false;
      this.$refs.video.pause();
      clearInterval(this.interval);
      this.interval = undefined;

    },
    startAutoPlay(){
      if(this.autoplay) {
        this.autoplay = false;
        this.stopAutoPlay();
        this.$refs.video.pause();
        return;
      }
      this.autoplay = true;
      this.$refs.video.play();

      this.interval = setInterval(this.onPlayUpdate,30);
    },
    onPlayUpdate(){
      if(!this.$refs.video) {
        this.stopAutoPlay();
        return;
      }

      const playPercent = this.$refs.video.currentTime/this.$refs.video.duration;
      const targetPos = (this.$refs.dragger_container.getBoundingClientRect().width-146)*playPercent;
      gsap.set(this.$refs.dragger,{x:targetPos});

      if(playPercent >= 1) {
        this.$refs.video.currentTime = 0;
      }
    },
   
    updateClip() {
      if (!this.$refs.dragger) return;
      const offset = this.$refs.dragger_container.getBoundingClientRect();
      const pos = this.$refs.dragger.getBoundingClientRect();

      const x = pos.x - offset.x;

      const percent = x / (offset.width - pos.width);
      this.percent = percent;

      const time = this.$refs.video.duration * this.percent;

      if (this.$refs.video.fastSeek) {
        this.$refs.video.fastSeek(time);
      } else {
        this.$refs.video.currentTime = time;
      }
    },
    onExitClick() {
      this.$emit("exit");
    },
    onResize() {
      this.draggable[0].applyBounds();
      this.updateClip();
    },
    init() {
      this.onResize();
    }
  },
  watch: {
    stageWidth() {
      this.onResize();
    }
  },
  computed: {
    stageWidth() {
      return this.$screen.width;
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.interval = undefined;
  },

  mounted() {
    clearInterval(this.interval);
    this.interval = undefined;

    this.draggable = Draggable.create(this.$refs.dragger, {
      type: "x",
      bounds: this.$refs.dragger_container,
      throwProps: true,
      onDrag: () => {
        this.stopAutoPlay();
        this.updateClip();
      }
    });

    gsap.from(this.$el, {
      ease: "Expo.easeOut",
      duration: 2,
      scale: 1.2,
      alpha: 0
    });
  }
};
</script>

<style scoped lang="scss">
</style>